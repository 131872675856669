import styled, { css } from 'react-emotion';
import { theme, List } from '@ublend-npm/aulaui-next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

export const ExpandedFolderIcon = styled(ArrowDropDownIcon)`
  color: ${theme.color.designSystem.blackOpacity60};
  padding: 0;
  margin-left: -7px;
`;

export const CollapsedFolderIcon = styled(ArrowRightIcon)`
  color: ${theme.color.designSystem.blackOpacity60};
  padding: 0;
  margin-left: -7px;
`;

export const SectionList = styled(List)`
  padding: 4px 0 0 13px;
  margin-left: 10.5px;
  border-left: 1px solid ${theme.color.blackAlpha('0.12')};
`;

export const folderItem = css`
  .label {
    font-weight: ${theme.font.weight.semiBold};
    line-height: 22px;
    color: ${theme.color.text};
  }
`;

export const FolderButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const OverviewFolderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin: 4px;
`;

export const OverviewFolderTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.copy};
  font-weight: ${theme.font.weight.semiBold};
  line-height: ${theme.font.lineHeight.copy};
`;

export const OverviewFolderProgress = styled.div`
  font-size: ${theme.font.size.copy};
  font-weight: ${theme.font.weight.regular};
`;
