import React from 'react';
import PropTypes from 'prop-types';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { styleUtils, Tooltip, IconButton } from '@ublend-npm/aulaui-next';

import formatDate from '../../../../../../core/utils/formatDate';
import { HoverIconButton } from './MaterialListItem.styled';
import {
  MATERIAL_UNHIDE_TOOLTIP,
  MATERIAL_UNHIDE_FOLDER_TOOLTIP,
  MATERIAL_HIDE,
  MATERIAL_HIDE_FOLDER,
} from '../../../../../constants/texts';
import { ENTER } from '../../../../../utils/keyCodes';

const getLabel = (isFolder, hidden, scheduledFor) => {
  if (hidden && scheduledFor) {
    return `Scheduled for ${formatDate(scheduledFor)}. Click to change.`;
  }
  if (isFolder) {
    return hidden ? MATERIAL_UNHIDE_FOLDER_TOOLTIP : MATERIAL_HIDE_FOLDER;
  }
  return hidden ? MATERIAL_UNHIDE_TOOLTIP : MATERIAL_HIDE;
};

const getIcon = (hidden, scheduledFor) => {
  if (hidden && scheduledFor) {
    return ScheduleIcon;
  }

  return hidden ? VisibilityOffIcon : VisibilityIcon;
};

const getAriaMessage = (isFolder, hidden, scheduledFor) => {
  const object = isFolder ? 'folder' : 'page';
  if (hidden && scheduledFor) {
    return `The ${object} is currently scheduled to be published on ${formatDate(
      scheduledFor
    )}.`;
  }
  return `The ${object} is currently ${hidden ? 'hidden' : 'visible'}.`;
};

const MaterialVisibilityToggle = ({
  isFolder,
  hidden,
  scheduledFor,
  onToggleVisibility,
  openScheduleDialog,
}) => {
  const label = getLabel(isFolder, hidden, scheduledFor);
  const handleToggleClick = (e) => {
    e.stopPropagation();
    if (hidden && scheduledFor) {
      openScheduleDialog();
    } else {
      onToggleVisibility();
    }
  };

  const Button = hidden ? IconButton : HoverIconButton;

  return (
    <Tooltip title={label} position="top">
      <div>
        <Button
          icon={getIcon(hidden, scheduledFor)}
          size="xSmall"
          type="secondary"
          label={label}
          buttonAttributes={{ tabIndex: 0 }}
          onClick={handleToggleClick}
          onKeyDown={(e) => e.keyCode === ENTER && handleToggleClick()}
        />
        <p css={[styleUtils.visuallyHidden()]} aria-live="polite">
          {getAriaMessage(isFolder, hidden, scheduledFor)}
        </p>
      </div>
    </Tooltip>
  );
};

MaterialVisibilityToggle.propTypes = {
  isFolder: PropTypes.bool,
  hidden: PropTypes.bool.isRequired,
  scheduledFor: PropTypes.string,
  onToggleVisibility: PropTypes.func.isRequired,
  openScheduleDialog: PropTypes.func.isRequired,
};

MaterialVisibilityToggle.defaultProps = {
  isFolder: false,
  scheduledFor: null,
};

export default React.memo(MaterialVisibilityToggle);
