import { cx } from 'emotion';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ListItem, theme } from '@ublend-npm/aulaui-next';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import MaterialVisibilityToggle from './MaterialVisibilityToggle.container';
import { listItem, hiddenListItem } from './MaterialListItem.styled';
import {
  completedListItem,
  sectionItem,
  selectedListItem,
} from './SectionListItem.styled';
import { useDrag, useDrop } from './MaterialListItem.dnd';

const CLASSES = { text: 'label' };

const SectionListItem = (props) => {
  const {
    materialId,
    hidden,
    parent,
    isInstructor,
    isStudent,
    selected,
    onReorder,
    educatorOnly,
    sectionCompleted,
    ...otherProps
  } = props;
  const listItemRef = useRef(null);
  const dragDropProps = { ...props, isFolder: false };
  const [{ isDragging }, drag] = useDrag(dragDropProps);
  const [{ dropType }, drop] = useDrop(dragDropProps, listItemRef);

  drag(drop(listItemRef));
  return (
    <li
      style={{ padding: '2px 0px' }}
      ref={listItemRef}
      data-testid="materials-section-list-item"
    >
      <ListItem
        component="div"
        textTruncate
        className={cx(
          listItem,
          sectionItem,
          isStudent && sectionCompleted && completedListItem,
          hidden && hiddenListItem,
          selected && selectedListItem
        )}
        classes={CLASSES}
        rightDecoration={
          <>
            {isInstructor && !educatorOnly && (
              <MaterialVisibilityToggle materialId={materialId} />
            )}
            {isStudent && sectionCompleted && (
              <CheckCircleOutlineIcon
                style={{
                  color: theme.color.designSystem.textColors.green,
                  fontSize: `24px`,
                }}
                title="Progress Status"
                aria-hidden="false"
                aria-label="Material has been marked as completed"
                role="img"
                data-testid={`section-completed-icon-${materialId}`}
              />
            )}
          </>
        }
        isDraggable
        isDragging={isDragging}
        dropType={dropType}
        attributes={{ 'aria-current': selected && 'page' }}
        withTooltipInPosition="top"
        {...otherProps}
      />
    </li>
  );
};

SectionListItem.propTypes = {
  materialId: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  parent: PropTypes.string,
  isInstructor: PropTypes.bool.isRequired,
  isStudent: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onReorder: PropTypes.func.isRequired,
  educatorOnly: PropTypes.bool,
  sectionCompleted: PropTypes.bool.isRequired,
};

SectionListItem.defaultProps = {
  parent: null,
  educatorOnly: false,
};

export default React.memo(SectionListItem);
