/* eslint-env browser */
import 'babel-polyfill';
import { IdleTimerProvider } from 'react-idle-timer';
import PropTypes from 'prop-types';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import regenerateSessionToken from '@core/api/users/regenerateSessionToken';

import {
  Provider as AulaNextThemeProvider,
  Snackbar,
} from '@ublend-npm/aulaui-next';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { StyleRoot } from 'radium';
import { Provider } from 'react-redux';
import { Router, Route, IndexRedirect, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { configure as configureHotkeys } from 'react-hotkeys';
import { QueryClient, QueryClientProvider } from 'react-query';

import AulaDevTools from './components/containers/common/AulaDevTools';
import App from './components/presentational/App.container';
import DashboardRoute from './routes/Dashboard';
import LogoutRoute from './routes/Logout';
import FilesRoute from './routes/Files';
import { loginWithSecureAuthCookie } from '../core/actions/user/login';
import push from './utils/routing';
import {
  analyticsTrackEvent,
  init as analyticsInit,
} from '../core/utils/analytics';
import { SESSION_TIMEOUT } from './constants/texts';
import { getQueryString } from '../core/utils/queryParams';
import * as analyticsTypes from '../core/constants/analytics';
import Raven from '../core/utils/raven';
import Platform from '../core/utils/platform';
import './fonts/font.css';
import redirectToGeneral from './utils/redirectToGeneral';
import addAxiosInterceptors from './utils/addAxiosInterceptors';

configureHotkeys({
  ignoreEventsCondition: () => false,
});

export const TWENTY_FIVE_MINUTES_INTERVAL = 25 * 60 * 1000;
export const THIRTY_MINUTES_INTERVAL = 30 * 60 * 1000;

class UserAuthError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UserAuthError';
  }
}

const loadApp = async (store, institutionData) => {
  const user = await store.dispatch(loginWithSecureAuthCookie());

  if (!user || user.error) {
    throw new UserAuthError(user.error);
  }

  await analyticsInit(store.getState);
  const loginEvent = getQueryString('login');
  if (loginEvent === 'true') {
    analyticsTrackEvent(analyticsTypes.CODE_LOGIN);
  } else {
    analyticsTrackEvent(analyticsTypes.LAUNCH_AULA);
  }

  Raven.configureScope((scope) => {
    if (
      store.getState() &&
      store.getState().user &&
      store.getState().user.user
    ) {
      scope.setUser({ id: store.getState().user.user.id });
    }
  });

  Raven.setTags({
    institution: institutionData.general.institution,
    Platform,
  });
};

const WebApp = (props) => {
  const { store, institutionData } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [history, setHistory] = useState();
  const [isSessionTimedout, setIsSessionTimedout] = useState(false);

  const isIdleRef = useRef(false);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const ldClient = useLDClient();
  const allFlags = ldClient?.allFlags();

  const handleRegenerateSessionToken = async () => {
    try {
      // regenerate session token api
      await regenerateSessionToken();
    } catch (error) {
      if (error.status === 401) {
        setIsSessionTimedout(true);
      }
    }
  };

  const regenerateSessionTokenScheduler = useCallback(() => {
    if (allFlags && allFlags['ft-session-token-refresh-enabled-202412']) {
      handleRegenerateSessionToken();
      const interval = setInterval(() => {
        if (!isIdleRef.current) {
          // check wether is the app still loading and also the app has not been idle for 30 minutes to regenerate session token
          handleRegenerateSessionToken();
        } else if (isIdleRef.current) {
          setIsSessionTimedout(true);
        } else {
          clearInterval(interval);
        }
      }, TWENTY_FIVE_MINUTES_INTERVAL);
    }
  }, []);

  const onLoadSuccess = () => {
    setHistory(syncHistoryWithStore(hashHistory, store));
    setIsLoading(false);
    addAxiosInterceptors();
    regenerateSessionTokenScheduler();
  };

  useEffect(() => {
    loadApp(store, institutionData)
      .then(onLoadSuccess)
      .catch((error) => {
        Raven.captureException(error, {
          tags: {
            context: 'loadApp',
          },
        });

        if (error.name === 'UserAuthError') {
          redirectToGeneral({ returnAfterLogin: true });
          return;
        }

        onLoadSuccess();
      });
  }, []);

  if (isLoading) {
    return null;
  }

  const handleOnIdle = () => {
    isIdleRef.current = true;
  };

  const handleSessionLogout = () => {
    setIsSessionTimedout(false);
    push('/logout');
  };

  return (
    <IdleTimerProvider timeout={THIRTY_MINUTES_INTERVAL} onIdle={handleOnIdle}>
      <Snackbar
        open={isSessionTimedout}
        message={SESSION_TIMEOUT}
        autoHideDuration={null}
        onClose={handleSessionLogout}
      />
      <AulaNextThemeProvider>
        <StyleRoot>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <Router history={history}>
                <Route path="/" component={App}>
                  {LogoutRoute}
                  {DashboardRoute(queryClient, ldClient)}
                  {FilesRoute}
                  <IndexRedirect to="dashboard" />
                </Route>
              </Router>
            </QueryClientProvider>
          </Provider>
        </StyleRoot>
        <AulaDevTools />
      </AulaNextThemeProvider>
    </IdleTimerProvider>
  );
};

WebApp.propTypes = {
  store: PropTypes.shape().isRequired,
  institutionData: PropTypes.shape().isRequired,
};

export default WebApp;
