import { AulaStore } from '../types/state';

/* eslint-disable no-unused-expressions */
let store: AulaStore;

// This avoids importing getStore from the app, which would introduce a circular
// dependency. Obviously, this is a heinous hack and will be removed.
// The path forward is to move all state management to the ducks pattern.
// Ducks have an initialisation function that can take services such as a
// store provider.
const getStore = () => store;
export const setStore = (newStore: AulaStore) => {
  store = newStore;
};

/**
 * The following three get base url functions all checks env variables for specific `BASE_${service}` first and returns
 * that, else it will return the api url in state
 * @param {string} service key in process.env to check for, prefixed with `BASE_`
 * @returns {string} the full base api url
 */

const getBaseUrl = (service?: string) => {
  if (service) {
    const serviceEndpoint = process.env[`BASE_${service}`];
    if (serviceEndpoint) return serviceEndpoint;
  }
  return getStore().getState().institution.keys.endpoints.api;
};

const getBaseUrlV2 = (service) => {
  if (service) {
    const serviceEndpoint = process.env[`BASE_${service}`];
    if (serviceEndpoint) return serviceEndpoint;
  }
  return getStore().getState().institution.keys.endpoints.apiV2;
};

const getServiceBaseUrl = (service) => {
  if (service) {
    const serviceEndpoint = process.env[`BASE_${service}`];
    if (serviceEndpoint) return serviceEndpoint;
  }
  return getStore().getState().institution.keys.endpoints.services;
};

const getSocketEndPoint = () => (getState) => {
  const state = getState();
  const {
    institution: {
      general: { base_domain: baseDomain },
    },
  } = state;
  return `https://services.${baseDomain}:44300/user`;
};

export const WEBSOCKET_ENDPOINT = getSocketEndPoint();

export const LOGIN_CODE_REQUEST = () =>
  `${getBaseUrlV2('LOGIN_CODE_REQUEST')}/auth/login/request`;

export const LOGIN_CODE_VALIDATE = () =>
  `${getBaseUrlV2('LOGIN_CODE_VALIDATE')}/auth/login/validate`;

export const LOGOUT = () => `${getBaseUrlV2('LOGOUT')}/auth/logout`;

export const CLASSROOM_CODEGEN = () =>
  `${getBaseUrl('CLASSROOM_CODEGEN')}/classroom/code/generate/`;

export const CLASSROOM_ROLES_ADD = () =>
  `${getBaseUrl('CLASSROOM_ROLES_ADD')}/classroom/role/add`;

export const CLASSROOM_ROLES_REMOVE = () =>
  `${getBaseUrl('CLASSROOM_ROLES_REMOVE')}/classroom/role/remove`;

export const CLASSROOM_MATERIAL_FOLDERS = (classRoomId) =>
  `${getBaseUrlV2(
    'CLASSROOM_MATERIAL_FOLDERS'
  )}/materials/spaces/${classRoomId}/material/folders`;

export const CLASSROOM_MATERIAL_FOLDER = (classRoomId, folderId) =>
  `${getBaseUrlV2(
    'CLASSROOM_MATERIAL_FOLDER'
  )}/materials/spaces/${classRoomId}/material/folders/${folderId}`;

export const CLASSROOM_GET_MATERIAL_SECTIONS = (
  classRoomId,
  skipContent = true,
  onlyDeleted = false
) =>
  `${getBaseUrlV2(
    'CLASSROOM_GET_MATERIAL_SECTIONS'
  )}/materials/spaces/${classRoomId}/material/sections?skipContent=${skipContent}&onlyDeleted=${onlyDeleted}`;

export const CLASSROOM_EXPORT_MATERIAL_SECTIONS = (classRoomId, educatorOnly) =>
  `${getBaseUrlV2(
    'CLASSROOM_EXPORT_MATERIAL_SECTIONS'
  )}/materials/spaces/${classRoomId}/material/export?educatorOnly=${educatorOnly}`;

export const CLASSROOM_POST_MATERIAL_SECTIONS = (classRoomId) =>
  `${getBaseUrlV2(
    'CLASSROOM_POST_MATERIAL_SECTIONS'
  )}/materials/spaces/${classRoomId}/material/sections`;

export const CLASSROOM_MATERIAL_SECTION = (classRoomId, sectionId) =>
  `${getBaseUrlV2(
    'CLASSROOM_MATERIAL_SECTION'
  )}/materials/spaces/${classRoomId}/material/sections/${sectionId}`;

export const CLASSROOM_MATERIAL_SECTION_VERSIONS = (classRoomId, sectionId) =>
  `${getBaseUrlV2(
    'CLASSROOM_MATERIAL_SECTION_VERSIONS'
  )}/materials/spaces/${classRoomId}/material/sections/${sectionId}/versions`;

export const CLASSROOM_MATERIAL_SECTION_VERSION = (classRoomId, sectionId) =>
  `${getBaseUrlV2(
    'CLASSROOM_MATERIAL_SECTION_VERSION'
  )}/materials/spaces/${classRoomId}/material/sections/${sectionId}/version`;

export const CLASSROOM_MATERIAL_SECTION_GET_VERSION = (
  classRoomId,
  sectionId,
  versionId
) =>
  `${getBaseUrlV2(
    'CLASSROOM_MATERIAL_SECTION_GET_VERSION'
  )}/materials/spaces/${classRoomId}/material/sections/${sectionId}/versions/${versionId}`;

export const UPDATE_MATERIAL_SECTION_PROGRESS = (
  spaceId: string,
  sectionId: string
) =>
  `${getBaseUrlV2(
    'UPDATE_MATERIAL_SECTION_PROGRESS'
  )}/materials/spaces/${spaceId}/material/sections/${sectionId}/progress`;

export const REQUEST_FAVICON = (url) =>
  `${getBaseUrl('REQUEST_FAVICON')}/image/favicon?url=${url}`;

export const INBOX_CONVERSATIONS = (includeClosed = false) =>
  `${getBaseUrlV2('INBOX_CONVERSATIONS')}/conversations${
    includeClosed ? '?includeClosed=true' : ''
  }`;

export const INBOX_CONVERSATION = (conversationId) =>
  `${getBaseUrlV2('INBOX_CONVERSATION')}/conversations/${conversationId}`;

export const INBOX_CONVERSATION_MESSAGES = (conversationId) =>
  `${getBaseUrlV2(
    'INBOX_CONVERSATION_MESSAGES'
  )}/conversations/${conversationId}/messages`;

export const INBOX_MESSAGE = (conversationId, messageId) =>
  `${getBaseUrlV2(
    'INBOX_MESSAGE'
  )}/conversations/${conversationId}/messages/${messageId}`;

export const INBOX_PARTICIPATION = (conversationId, participantId) =>
  `${getBaseUrlV2(
    'INBOX_PARTICIPATION'
  )}/conversations/${conversationId}/participants/${participantId}`;

export const CHANGE_AVATAR = () =>
  `${getBaseUrl('CHANGE_AVATAR')}/user/avatar/upload/`;

export const STARTUP_DATA = () => `${getBaseUrlV2('STARTUP_DATA')}/startup`;

export const FETCH_FEED = ({
  spaceId,
  until,
  after,
  tagId,
  important,
  scheduled,
  feedType,
}) => {
  const params = new URLSearchParams();
  if (spaceId) params.append('space', spaceId);
  if (until) params.append('until', until.toISOString());
  if (after) params.append('after', after.toISOString());
  if (tagId) params.append('tag', tagId);
  if (important) params.append('important', 'true');
  if (scheduled) params.append('scheduled', 'true');
  if (feedType) params.append('feedType', feedType);
  return `${getBaseUrlV2('FETCH_FEED')}/posts/feed?${params}`;
};

export const FETCH_IMPORTANT_POSTS = (spaceId, until) =>
  `${getBaseUrlV2(
    'FETCH_IMPORTANT_POSTS'
  )}/posts/important?space=${spaceId}&until=${until.toISOString()}`;

export const ELASTICSEARCH = () => `${getBaseUrl('ELASTICSEARCH')}/search`;

export const SEARCH = () => `${getBaseUrlV2('SEARCH')}/search`;

export const ANALYTICS = () => `${getBaseUrlV2('ANALYTICS')}/analytics`;

export const USERS = () => `${getBaseUrlV2('USERS')}/users`;

export const USER = (userId) => `${getBaseUrlV2('USER')}/users/${userId}`;

export const REGEN_SESSION_TOKEN = () =>
  `${getBaseUrlV2('USERS')}/users/regenerateSessionToken`;

export const LTI_PROVIDERS = () =>
  `${getBaseUrlV2('LTI_PROVIDERS')}/lti/providers`;

export const LTI_CONSUMER = () =>
  `${getBaseUrlV2('LTI_CONSUMER')}/lti-consumer`;

export const ENGAGEMENT_ANALYTICS = () =>
  `${getBaseUrlV2('ENGAGEMENT_ANALYTICS')}/engagement`;

export const ENGAGEMENT_DASHBOARD = () => `${ENGAGEMENT_ANALYTICS()}/dashboard`;

export const ASSIGNMENT = () =>
  `${getServiceBaseUrl('AULA_ASSIGNMENTS')}/assignments`;

export const AULA_ASSIGNMENTS = () =>
  `${getBaseUrlV2('AULA_ASSIGNMENTS')}/assignments`;

export const SUBMISSIONS = (assignmentId) =>
  `${getBaseUrlV2('AULA_ASSIGNMENTS')}/assignments/${assignmentId}/submissions`;

export const HANDIN_SUBMISSIONS = () =>
  `${getBaseUrlV2('HANDIN_SUBMISSIONS')}/handin-submissions`;

export const HANDIN_SUBMISSIONS_EXPORT = (assignmentExternalId) =>
  `${getBaseUrlV2(
    'HANDIN_SUBMISSIONS'
  )}/handin-submissions/exports/${assignmentExternalId}`;

export const ACCESS_TOKEN_ENDPOINT = (spaceId) =>
  `${getBaseUrlV2(
    'ACCESS_TOKEN_ENDPOINT'
  )}/users/authorize?aud=handin&scope=space_${spaceId}&platform=web`;

export const FILE_UPLOADER_V2 = () =>
  `${getBaseUrlV2('FILE_UPLOADER_V2')}/file/upload`;

export const FILE_URL = () => {
  const { base_domain: baseDomain } = getStore().getState().institution.general;

  return `https://files.${baseDomain}`;
};

export const POST = (postId) => `${getBaseUrlV2('POST')}/posts/${postId}`;

export const POSTS = (spaceId) =>
  `${getBaseUrlV2('POSTS')}/posts?space=${spaceId}`;

export const FETCH_COMMENTS = ({ entityType, entityId }) =>
  `${getBaseUrlV2('FETCH_COMMENTS')}/comments/type/${entityType}/${entityId}`;

export const CREATE_COMMENT = () =>
  `${getBaseUrlV2('CREATE_COMMENT')}/comments`;

export const COMMENT = (commentId) =>
  `${getBaseUrlV2('COMMENT')}/comments/${commentId}`;

export const TAGS = (spaceId) =>
  `${getBaseUrlV2('TAGS')}/spaces/${spaceId}/tags`;

export const POST_TAG = ({ postId, tagId }) =>
  `${getBaseUrlV2('POST_TAG')}/posts/${postId}/tags/${tagId}`;

export const GET_SPACE = ({ spaceId }) =>
  `${getBaseUrlV2('GET_SPACE')}/spaces/${spaceId}?educators=true`;

export type SPACE_PARTICIPANTS_PARAMS = {
  spaceId: string;
  skip?: number;
  sort?: string;
  limit?: number;
  role: string;
};

export const SPACE_PARTICIPANTS = ({
  spaceId,
  skip = 0,
  sort = 'lastName',
  limit = 100,
  role,
}: SPACE_PARTICIPANTS_PARAMS) =>
  `${getBaseUrlV2(
    'SPACE_PARTICIPANTS'
  )}/spaces/${spaceId}/participants?limit=${limit}&skip=${skip}&sort=${sort}${
    role ? `&role=${role}` : ''
  }`;

export const ADD_PARTICIPANT_TO_SPACE = (spaceId) =>
  `${getBaseUrlV2('ADD_PARTICIPANT_TO_SPACE')}/spaces/${spaceId}/participants`;

export const SPACE_PARTICIPANTS_HISTORY = ({
  spaceId,
  before = new Date().toISOString(),
  limit = 50,
}) =>
  `${getBaseUrlV2(
    'SPACE_PARTICIPANTS_HISTORY'
  )}/spaces/${spaceId}/participants/history?before=${before}&limit=${limit}`;

export const REMOVE_PARTICIPANT_FROM_SPACE = (spaceId, userId) =>
  `${getBaseUrlV2(
    'REMOVE_PARTICIPANT_FROM_SPACE'
  )}/spaces/${spaceId}/participants/${userId}`;

export const SPACE = (spaceId) => `${getBaseUrlV2('SPACE')}/spaces/${spaceId}`;

export const GROUP_SETS = ({
  setId,
  spaceId,
  search,
  excludeInstitutionManaged,
}) => {
  const query = `${spaceId ? `?space=${spaceId}` : ''}${
    excludeInstitutionManaged ? `&excludeInstitutionManaged=true` : ''
  }${search ? `&search=${search}` : ''}`;
  const set = setId ? `/${setId}` : '';
  return `${getBaseUrlV2('GROUP_SETS')}/groups/sets${set}${query}`;
};

export const GROUP_SET = (setId) =>
  `${getBaseUrlV2('GROUP_SET')}/groups/sets/${setId}`;

export const VALIDATE_GROUP_EMAILS = () =>
  `${getBaseUrlV2('VALIDATE_GROUP_EMAILS')}/groups/validateEmails`;

export const GROUP_GROUPS = () =>
  `${getBaseUrlV2('GROUP_GROUPS')}/groups/groups`;

export const GROUP_GROUP = (groupId) =>
  `${getBaseUrlV2('GROUP_GROUP')}/groups/groups/${groupId}`;

export const GROUP_GENERATE = (setId) =>
  `${getBaseUrlV2('GROUP_GENERATE')}/groups/sets/${setId}/generate`;

export const REACTIONS = () => `${getBaseUrlV2('REACTIONS')}/reactions`;

export const REACTION = (reactionId) =>
  `${getBaseUrlV2('REACTION')}/reactions/${reactionId}/user`;

export const NOTIFICATION_SETTINGS = () =>
  `${getBaseUrlV2('NOTIFICATION_SETTINGS')}/notifications/settings`;

export const NOTIFICATIONS = (unreadOnly = false) =>
  `${getBaseUrlV2('NOTIFICATIONS')}/notifications${
    unreadOnly ? '?unreadOnly=true' : ''
  }`;

export const FILE_AUTH_COOKIE = () =>
  `${getBaseUrlV2('FILE_AUTH_COOKIE')}/file/cookie`;

export const API_URL = () => getBaseUrlV2('API_URL');

export const ASSIGNMENT_GRADE = ({ assignmentId }: { assignmentId: string }) =>
  `${getBaseUrlV2('AULA_ASSIGNMENTS')}/assignments/${assignmentId}/grade`;

export const STAR_SPACE = ({ spaceId }) =>
  `${getBaseUrlV2('STAR_SPACE')}/spaces/${spaceId}/star`;

export const LTI_1P3_START = () =>
  `${getBaseUrlV2('LTI_CONSUMER')}/lti/1p3/start`;

export const LTI_1P3_POST_ASSIGNMENT = ({ spaceId }: { spaceId: string }) =>
  `${getBaseUrlV2('LTI_CONSUMER')}/lti/1p3/ags/${spaceId}/assignments`;

export const LTI_1P3_PUT_ASSIGNMENT = ({ spaceId, assignmentId }) =>
  `${getBaseUrlV2(
    'LTI_CONSUMER'
  )}/lti/1p3/ags/${spaceId}/assignments/${assignmentId}`;

export const TOGGLE_HIDE_ASSIGNMENT = ({ assignmentId }) =>
  `${getBaseUrlV2('AULA_ASSIGNMENTS')}/assignments/${assignmentId}/toggle-hide`;
