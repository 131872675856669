import { cx } from 'emotion';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ListItem, theme } from '@ublend-npm/aulaui-next';
import Collapse from '@material-ui/core/Collapse';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import ProgressBar from '../../../common/ProgressBar/ProgressBar';
import SectionListItem from './SectionListItem.container';
import FolderListItemMenu from './FolderListItemMenu.container';
import MaterialVisibilityToggle from './MaterialVisibilityToggle.container';
import AddSectionButton from './AddSectionButton.container';
import { listItem, hiddenListItem } from './MaterialListItem.styled';
import {
  ExpandedFolderIcon,
  CollapsedFolderIcon,
  SectionList,
  FolderButtons,
  folderItem,
  OverviewFolderContainer,
  OverviewFolderTitleContainer,
  OverviewFolderProgress,
} from './FolderListItem.styled';
import { useDrag, useDrop } from './MaterialListItem.dnd';

const CLASSES = {
  text: 'label',
};

const FolderListItem = (props) => {
  const {
    materialId,
    expanded,
    primaryText,
    hidden,
    toggleFolder,
    materialIds,
    isInstructor,
    isStudent,
    onReorder,
    educatorOnly,
    isOverview,
    allSectionsCompleted,
    sectionsProgress,
    ...otherProps
  } = props;
  const listItemRef = useRef(null);
  const dragDropProps = { ...props, isFolder: true, parent: null };
  const [{ isDragging }, drag] = useDrag(dragDropProps);
  const [{ dropType }, drop] = useDrop(dragDropProps, listItemRef);
  const ariaLabelId = `folder-button-${materialId}`;
  const { numberOfSections, numberOfSectionsCompleted } = sectionsProgress;

  const showGreenTick = isStudent && !isOverview && allSectionsCompleted;
  const showProgressbar = isStudent && isOverview;
  const accessibilityLabel = showProgressbar
    ? `${primaryText}. With ${numberOfSectionsCompleted} out of ${numberOfSections} materials completed`
    : primaryText;

  drag(drop(listItemRef));

  const OverviewFolder = () => {
    const showStepsCounter =
      numberOfSections > 0 && numberOfSectionsCompleted !== undefined;

    return (
      <OverviewFolderContainer aria-label={accessibilityLabel}>
        <OverviewFolderTitleContainer>
          {primaryText}
          {showStepsCounter && (
            <OverviewFolderProgress data-testid="folder-steps-counter">
              {`${numberOfSectionsCompleted}/${numberOfSections}`}
            </OverviewFolderProgress>
          )}
        </OverviewFolderTitleContainer>
        <ProgressBar
          numberOfSections={numberOfSections}
          numberOfSectionsCompleted={numberOfSectionsCompleted}
        />
      </OverviewFolderContainer>
    );
  };

  return (
    <React.Fragment>
      <li
        style={{ padding: '8px 0px' }}
        ref={listItemRef}
        data-testid="materials-folder-list-item"
      >
        <ListItem
          component="div"
          textTruncate
          className={cx(listItem, folderItem, hidden && hiddenListItem)}
          classes={CLASSES}
          leftDecoration={
            expanded ? <ExpandedFolderIcon /> : <CollapsedFolderIcon />
          }
          accessibilityLabel={accessibilityLabel}
          rightDecoration={
            <>
              {isInstructor && (
                <FolderButtons id={ariaLabelId}>
                  <FolderListItemMenu
                    materialId={materialId}
                    ariaLabelId={ariaLabelId}
                    educatorOnly={educatorOnly}
                  />
                  {!educatorOnly && (
                    <MaterialVisibilityToggle
                      isFolder
                      materialId={materialId}
                    />
                  )}
                </FolderButtons>
              )}
              {showGreenTick && (
                <CheckCircleOutlineIcon
                  style={{
                    color: `${theme.color.designSystem.textColors.green}`,
                    fontSize: `24px`,
                  }}
                  title="Progress Status"
                  aria-label="All materials in section have been marked as complete"
                  aria-hidden="false"
                  role="img"
                  data-testid={`folder-completed-icon-${materialId}`}
                />
              )}
            </>
          }
          onClick={toggleFolder}
          primaryText={!isOverview ? primaryText : undefined}
          isDraggable
          isDragging={isDragging}
          dropType={dropType}
          withTooltipInPosition="top"
          attributes={{
            'aria-expanded': expanded,
            'data-testid': 'material-folder',
          }}
          {...otherProps}
        >
          {showProgressbar ? <OverviewFolder /> : null}
        </ListItem>
      </li>
      <Collapse
        aria-hidden={!expanded}
        in={expanded}
        component="li"
        style={{ paddingBottom: expanded ? '8px' : '0' }}
        data-testid={`materials-folder-list-item-sections-${
          expanded ? 'expanded' : 'collapsed'
        }`}
      >
        <SectionList component="ul">
          {materialIds.map((id) => (
            <SectionListItem
              educatorOnly={educatorOnly}
              key={id}
              materialId={id}
            />
          ))}
          {isInstructor ? (
            <AddSectionButton
              folderId={materialId}
              educatorOnly={educatorOnly}
            />
          ) : null}
        </SectionList>
      </Collapse>
    </React.Fragment>
  );
};

FolderListItem.propTypes = {
  materialId: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
  materialIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isInstructor: PropTypes.bool.isRequired,
  isStudent: PropTypes.bool.isRequired,
  toggleFolder: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  educatorOnly: PropTypes.bool,
  primaryText: PropTypes.string,
  isOverview: PropTypes.bool,
  allSectionsCompleted: PropTypes.bool.isRequired,
  sectionsProgress: PropTypes.shape({
    numberOfSections: PropTypes.number,
    numberOfSectionsCompleted: PropTypes.number,
  }),
};

FolderListItem.defaultProps = {
  educatorOnly: false,
  isOverview: false,
  primaryText: '',
  sectionsProgress: {},
};

export default FolderListItem;
